import { DisplayValue, ValueWithUnit } from '@/types';
import { components } from '@/types/be-schema';
import { BodyTypeIconId } from '../../../components/atoms/BodyTypeIcon';
import { RawTrimSpecs, TrimSpecs } from '../build/types';
import { DealerType } from '../dealer/types';
import { IncentiveResponse } from '../incentives/types';
import { Range, SortedHighlightsBadges } from '../shared/types';

export type FuelType = 'Electric' | 'Hybrid';

type RawBatteryInfo = {
  size?: string;
  tech?: string;
  warranty?: string;
};

export type BatteryInfoType = {
  batteryTech: string;
  batterySize: string | null;
  batteryWarranty: string | null;
  batteryHealthEstimate?: number | null;
};

export type RawChargingInfo = {
  ChargingTime120V: string;
  ChargingTime240V: string;
  ChargingTimeDC: string;
  ChargingPeakRateDC: string;
  ChargingConnectorDC: string;
};

export type ChargingInfoType = {
  isEV?: boolean;
  level1: string;
  level2: string;
  level3: string;
  peakChargeRate: string;
  chargingConnectorDC: string;
};

// /vehicle_details_page/price_history/{vin}
export interface PriceHistory {
  value: number;
  unit: string;
  date: Date;
}

export type VehicleIncentives = 'Federal';
export enum VehicleBadgeIds {
  'ELIGIBLE FOR FEDERAL INCENTIVES' = 'Qualifies for incentives',
}

export type RawBatteryHealth = {
  BatteryHealthEstimate: number;
};

export type RawVehicleListingBadge = VehicleListingBadge | undefined;
export type VehicleListingBadge = keyof typeof VehicleBadgeIds;

export interface VehiclePreview {
  year: number;
  make: string;
  model: string;
  trim: string;
}

export interface BodyTypeOption {
  icon: {
    iconId: BodyTypeIconId;
    className: string;
  };
  value: string;
}

export interface ErrorPageProps {
  autoCompleteResults: {
    searchMakes: string[];
    searchModels: Record<string, string[]>;
  };
}

export type RawListingTypeMinimal = {
  DealerId: string;
  ListingID: string;
  StockNumber: string;
  Mileage?: { unit: string; value: number };
  Price: number | { unit: string; value: number | null };
  Dealership: string;
  DealerGroupName?: string;
  Makes?: string[];
  Address: string;
  City: string;
  State: string;
  Postal: string;
  Phone: string;
  Email: string[];
  Photo?: string;
  Photos?: string[];
};

export type RawListingType = RawListingTypeMinimal & {
  Vin: string;
  CompanyID?: string;
  Desc: string;
  PhotoTimeStamps: string[];
  Warranty: number;
  WarrantyDescription: string;
  FinanceDownPayment: string;
  FinanceLeaseKilometers: string;
  FinanceNumberOfPayments: string;
  FinancePaymentType: string;
  MFGCertificationProgram: string;
  AdLastModifiedDate: string;
  CategoryId: string;
  created_at: string;
  updated_at: string;
  distance?: number;
  State?: string;
  Scraped?: boolean;
  URL?: string;
  Location?: string;
  IsActive?: boolean;
  Photos: string[];
};

// /api/inventory/listings

export type VehicleListingModel = components['schemas']['VehicleListingModel'];

export type VehicleListingType = {
  vin: string;
  make: string;
  model: string;
  year: number;
  trim: string;
  condition: string;
  dealer: DealerType | null;
  dealershipType: string;
  images: string[];
  price: DisplayValue;
  fuelType: string;
  drivetrain: string;
  incentives: IncentiveResponse;
  styleId?: number;
  listingId: string;
  shortListingId: string | null;
  badges: string[];
  body: string;
  mileage: ValueWithUnit;
  batteryInfo: BatteryInfoType;
  rangeInfo: Range;
  stockNumber: string;
};

export type SpecificationType = {
  title: string;
  text: string;
};

// /api/inventory/listing/{listingId}
export type RawVehicleType = {
  Vin: string;
  Badges: RawVehicleListingBadge[];
  Battery?: RawBatteryInfo;
  BatteryWarranty: string;
  Charging: RawChargingInfo;
  DealershipType: string;
  Year: number;
  Make: string;
  Model: string;
  Trim: string;
  FuelType: string;
  Condition: string;
  Transmission: string;
  Doors: string;
  NoOfPassengers: string;
  InteriorColor: string;
  IntColorHexCode: string;
  IntColorCode: string;
  ExteriorColor: string;
  ExtColorHexCode: string;
  ExtColorCode: string;
  Seats: string;
  Drive: string;
  Performance: string;
  EngineSize: string;
  Cylinders: string;
  Body: string;
  Options: string[];
  Features: { id: string; name: string }[];
  created_at: string;
  last_updated_at: string;
  listing: RawListingType;
  RangeAllElectric?: ValueWithUnit;
  RangeHybridElectric?: ValueWithUnit;
  Incentives: { [key in VehicleIncentives]: number } | null;
  ShortListingID: string | null;
  StyleId?: number;
  incentives: IncentiveResponse | null;
  s3_photos: string[];
  style: string;
  gas_rate: number;
  ev_rate: number;
  priceHistory: PriceHistory[];
  vehicleDetails: RawTrimSpecs;
  isSaved: boolean;
};

export interface MinimalVehicle extends VehiclePreview {
  listingId: string;
  vin?: string;
  year: number;
  make: string;
  images: string[];
  model: string;
  trim: string;
  body: string;
  dealerName?: string;
  price: DisplayValue;
  badges: string[];
  shortListingId: string | null;
}

export interface VehicleType extends Omit<MinimalVehicle, 'dealerName'> {
  vin: string;
  fuelType: string;
  condition: string;
  options: string[];
  features: string[];
  stockNumber: string;
  dealer: DealerType | null;
  mileage?: ValueWithUnit;
  description: string;
  dealershipType: string;
  imageTimestamps: string[];
  distance: string;
  overviews: SpecificationType[];
  performances: SpecificationType[];
  chargingInfo: ChargingInfoType;
  batteryInfo: BatteryInfoType;
  rangeInfo: Range;
  scraped: boolean;
  url?: string;
  location?: string;
  interiorColor: string;
  interiorColorCode: string;
  interiorColorHex: string;
  exteriorColor: string;
  exteriorColorCode: string;
  exteriorColorHex: string;
  seats: string;
  drivetrain: string;
  horsepower: string | null;
  batteryWarranty: string;
  listingWarranty: {
    value: string;
    description: string;
  };
  styleId?: number;
  incentives: IncentiveResponse | null;
  createdAt: string;
  listedForDays: number;
}

export type VehicleTypeWithTrimSpecs = Omit<VehicleType, 'features'> & {
  trimSpecs: TrimSpecs;
  highlightBadges: SortedHighlightsBadges | null;
};

export type FetchListingQueryStringParams = {
  make?: string;
  model?: string;
  condition?: string;
  year_start?: string;
  year_end?: string;
  price_start?: string;
  price_end?: string;
  range_start?: string;
  range_end?: string;
  fuel_type?: string;
  max_mileage?: string;
  postal?: string;
  radius_km?: string;
  sort?: string;
  skip?: number;
  limit?: number;
  has_ev_incentives?: boolean;
  body?: string;
};

export interface FetchProductsResult {
  content: VehicleListingType[];
  size: number;
  total: number;
  hasExactMatch: boolean;
}

/** Shown at the bottom of the VLP for SEO performance. See EVCOM-2291 for more info. */
export interface SeoEnrichmentContent {
  title: string;
  content: string;
  /** If `true`, show the total amount of vehicles matching a query, along with the minimum and maximum price. */
  showTotalAndPriceRange?: boolean;
  /** If `true`, show 3-4 of the most common models and their prices given the fetched result. */
  showPopularModels?: boolean;
  make?: string;
  model?: string;
  year?: string;
  location?: string;
}
